export var httpProtocol = 'https://';
export var frontendUri = 'merchi.co/';
export var backendUri = 'https://api.merchi.co/';
export var websocketServer = 'https://websockets.merchi.co/;';
export var jsonUrlMap = { 'static': { args: ['filename'], rule: '/static/<path:filename>', route: '/static/:filename' },
    'index': { args: [], rule: '/', route: '/' },
    'login': { args: [], rule: '/login', route: '/login' },
    'customisable_merchandise_product': { args: ['id'], rule: '/customisable-merchandise/<int:id>', route: '/customisable-merchandise/:id' },
    'about': { args: [], rule: '/about', route: '/about' },
    'contact': { args: [], rule: '/contact', route: '/contact' },
    'supplier_resell_product': { args: ['id'], rule: '/supplier/resell-products/<int:id>', route: '/supplier/resell-products/:id' },
    'supplier_resell_products': { args: [], rule: '/supplier/resell-products', route: '/supplier/resell-products' },
    'customisable_merchandise': { args: [], rule: '/customisable-merchandise', route: '/customisable-merchandise' },
    'made_on_demand': { args: [], rule: '/made-on-demand', route: '/made-on-demand' },
    'pricing': { args: [], rule: '/pricing', route: '/pricing' },
    'pricing_new': { args: [], rule: '/pricing-new', route: '/pricing-new' },
    'privacy_policy': { args: [], rule: '/privacy-policy', route: '/privacy-policy' },
    'security': { args: [], rule: '/security', route: '/security' },
    'sellers': { args: [], rule: '/sellers', route: '/sellers' },
    'sign_up_seller': { args: [], rule: '/sign-up/seller', route: '/sign-up/seller' },
    'suppliers': { args: [], rule: '/suppliers', route: '/suppliers' },
    'terms_and_conditions': { args: [], rule: '/terms-and-conditions', route: '/terms-and-conditions' },
    'terms_and_conditions_subscription': { args: [], rule: '/terms-and-conditions', route: '/terms-and-conditions' },
    'terms_and_conditions_user': { args: [], rule: '/terms-and-conditions', route: '/terms-and-conditions' },
    'terms_and_conditions_intellectual_property': { args: [], rule: '/terms-and-conditions', route: '/terms-and-conditions' },
    'traditional_merchandise': { args: [], rule: '/traditional-merchandise', route: '/traditional-merchandise' },
    'public_host_static': { args: ['host', 'filename'], rule: '/static/<path:filename>', route: '/static/:filename' },
    'categories_list': { args: [], rule: '/categories/', route: '/categories/' },
    'merchi_categories_list': { args: [], rule: '/merchi_categories/', route: '/merchi_categories/' },
    'user_new': { args: [], rule: '/users/new/', route: '/users/new/' },
    'user_security': { args: ['identifier'], rule: '/users/<int:identifier>/security/', route: '/users/:identifier/security/' },
    'user_files': { args: ['identifier'], rule: '/users/<int:identifier>/files/', route: '/users/:identifier/files/' },
    'user_shipments': { args: ['identifier'], rule: '/users/<int:identifier>/shipments/', route: '/users/:identifier/shipments/' },
    'user_invoices': { args: ['identifier'], rule: '/users/<int:identifier>/invoices/', route: '/users/:identifier/invoices/' },
    'user_jobs': { args: ['identifier'], rule: '/users/<int:identifier>/jobs/', route: '/users/:identifier/jobs/' },
    'user_products': { args: ['identifier'], rule: '/users/<int:identifier>/products/', route: '/users/:identifier/products/' },
    'user_domains': { args: ['identifier'], rule: '/users/<int:identifier>/domains/', route: '/users/:identifier/domains/' },
    'user_info': { args: ['identifier'], rule: '/users/<int:identifier>/info/', route: '/users/:identifier/info/' },
    'user_ai': { args: ['identifier'], rule: '/users/<int:identifier>/ai/', route: '/users/:identifier/ai/' },
    'user_edit': { args: ['identifier'], rule: '/users/<int:identifier>/', route: '/users/:identifier/' },
    'users_list': { args: [], rule: '/users/', route: '/users/' },
    'user_delete': { args: ['id'], rule: '/users/delete/<int:id>/', route: '/users/delete/:id/' },
    'unsubscribe_invoice_reminders': { args: ['user_id'], rule: '/users/<int:user_id>/disable-invoice-reminders/', route: '/users/:user_id/disable-invoice-reminders/' },
    'reset_password': { args: [], rule: '/password/reset/request/', route: '/password/reset/request/' },
    'password_change': { args: ['reset_id', 'token'], rule: '/password/reset/change/<int:reset_id>-<string:token>/', route: '/password/reset/change/:reset_id-:token/' },
    'theme_css_settings': { args: [], rule: '/theme_css_settings/', route: '/theme_css_settings/' },
    'theme_new': { args: [], rule: '/themes/new/', route: '/themes/new/' },
    'theme_edit': { args: ['identifier'], rule: '/themes/<int:identifier>/', route: '/themes/:identifier/' },
    'theme_edit_template_products': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/products/', route: '/themes/:identifier/page/template/products/' },
    'theme_edit_template_product': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/product/', route: '/themes/:identifier/page/template/product/' },
    'theme_edit_template_jobs': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/jobs/', route: '/themes/:identifier/page/template/jobs/' },
    'theme_edit_template_quote_requested': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/quote_requested/', route: '/themes/:identifier/page/template/quote_requested/' },
    'theme_edit_template_drafting': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/drafting/', route: '/themes/:identifier/page/template/drafting/' },
    'theme_edit_template_invoices': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/invoices/', route: '/themes/:identifier/page/template/invoices/' },
    'theme_edit_template_invoice_paid': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/invoice_paid/', route: '/themes/:identifier/page/template/invoice_paid/' },
    'theme_edit_template_invoice': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/invoice/', route: '/themes/:identifier/page/template/invoice/' },
    'theme_edit_template_index': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/index/', route: '/themes/:identifier/page/template/index/' },
    'theme_edit_template_header': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/header/', route: '/themes/:identifier/page/template/header/' },
    'theme_edit_template_footer': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/footer/', route: '/themes/:identifier/page/template/footer/' },
    'theme_edit_template_error': { args: ['identifier'], rule: '/themes/<int:identifier>/page/template/error/', route: '/themes/:identifier/page/template/error/' },
    'theme_edit_templates': { args: ['identifier'], rule: '/themes/<int:identifier>/page/templates/', route: '/themes/:identifier/page/templates/' },
    'theme_edit_style_email': { args: ['identifier'], rule: '/themes/<int:identifier>/style/email/', route: '/themes/:identifier/style/email/' },
    'theme_edit_style_main': { args: ['identifier'], rule: '/themes/<int:identifier>/style/main/', route: '/themes/:identifier/style/main/' },
    'theme_edit_style': { args: ['identifier'], rule: '/themes/<int:identifier>/style/', route: '/themes/:identifier/style/' },
    'theme_edit_pages': { args: ['identifier'], rule: '/themes/<int:identifier>/pages/', route: '/themes/:identifier/pages/' },
    'theme_edit_menus': { args: ['identifier'], rule: '/themes/<int:identifier>/menus/', route: '/themes/:identifier/menus/' },
    'theme_edit_info': { args: ['identifier'], rule: '/themes/<int:identifier>/info/', route: '/themes/:identifier/info/' },
    'theme_logs': { args: ['identifier'], rule: '/themes/<int:identifier>/logs/', route: '/themes/:identifier/logs/' },
    'theme_edit_ai': { args: ['identifier'], rule: '/themes/<int:identifier>/ai/', route: '/themes/:identifier/ai/' },
    'themes_list': { args: [], rule: '/themes/', route: '/themes/' },
    'favicon': { args: [], rule: '/favicon.ico', route: '/favicon.ico' },
    'tasks': { args: [], rule: '/tasks/', route: '/tasks/' },
    'settings_security': { args: [], rule: '/settings/security/', route: '/settings/security/' },
    'settings_files': { args: [], rule: '/settings/files/', route: '/settings/files/' },
    'settings_shipments': { args: [], rule: '/settings/shipments/', route: '/settings/shipments/' },
    'settings_invoices': { args: [], rule: '/settings/invoices/', route: '/settings/invoices/' },
    'settings_jobs': { args: [], rule: '/settings/jobs/', route: '/settings/jobs/' },
    'settings_products': { args: [], rule: '/settings/products/', route: '/settings/products/' },
    'settings_domains': { args: [], rule: '/settings/domains/', route: '/settings/domains/' },
    'settings': { args: [], rule: '/settings/', route: '/settings/' },
    'settings_internal_tags': { args: [], rule: '/settings/internal_tags/', route: '/settings/internal_tags/' },
    'backup_new': { args: [], rule: '/backups/new/', route: '/backups/new/' },
    'backups_list': { args: [], rule: '/backups/', route: '/backups/' },
    'backup_delete': { args: ['id'], rule: '/backups/delete/<int:id>/', route: '/backups/delete/:id/' },
    'backup_restore': { args: ['id'], rule: '/backups/restore/<int:id>/', route: '/backups/restore/:id/' },
    'jobs_list': { args: [], rule: '/jobs/', route: '/jobs/' },
    'jobs_actions': { args: [], rule: '/jobs/actions/', route: '/jobs/actions/' },
    'job_complete': { args: ['id'], rule: '/jobs/<int:id>/complete/', route: '/jobs/:id/complete/' },
    'job_delete': { args: ['id'], rule: '/jobs/delete/<int:id>/', route: '/jobs/delete/:id/' },
    'job_recover': { args: ['id'], rule: '/jobs/recover/<int:id>/', route: '/jobs/recover/:id/' },
    'job_profile': { args: ['id'], rule: '/jobs/<int:id>/', route: '/jobs/:id/' },
    'job_production_assignment_drafting': { args: ['id', 'assignment_id'], rule: '/jobs/<int:id>/production/<int:assignment_id>/drafting/', route: '/jobs/:id/production/:assignment_id/drafting/' },
    'job_production_assignment': { args: ['id', 'assignment_id'], rule: '/jobs/<int:id>/production/<int:assignment_id>/', route: '/jobs/:id/production/:assignment_id/' },
    'job_shipping': { args: ['id'], rule: '/jobs/<int:id>/shipping/', route: '/jobs/:id/shipping/' },
    'job_production': { args: ['id'], rule: '/jobs/<int:id>/production/', route: '/jobs/:id/production/' },
    'job_notifications': { args: ['id'], rule: '/jobs/<int:id>/notifications/', route: '/jobs/:id/notifications/' },
    'job_notification': { args: ['id', 'notification_id'], rule: '/jobs/<int:id>/notification/<int:notification_id>/', route: '/jobs/:id/notification/:notification_id/' },
    'job_invoice': { args: ['id'], rule: '/jobs/<int:id>/invoice/', route: '/jobs/:id/invoice/' },
    'job_group_buy': { args: ['id'], rule: '/jobs/<int:id>/group_buy/', route: '/jobs/:id/group_buy/' },
    'job_client_file_download': { args: ['id'], rule: '/jobs/<int:id>/file_download/', route: '/jobs/:id/file_download/' },
    'job_drafting': { args: ['id'], rule: '/jobs/<int:id>/drafting/', route: '/jobs/:id/drafting/' },
    'job_info': { args: ['id'], rule: '/jobs/<int:id>/info/', route: '/jobs/:id/info/' },
    'job_conversation': { args: ['id'], rule: '/jobs/<int:id>/conversation/', route: '/jobs/:id/conversation/' },
    'job_supply_chain_request': { args: ['id'], rule: '/jobs/<int:id>/supply_chain_request/', route: '/jobs/:id/supply_chain_request/' },
    'job_reorder': { args: ['id'], rule: '/jobs/<int:id>/reorder/', route: '/jobs/:id/reorder/' },
    'pass_drafting': { args: ['id'], rule: '/jobs/<int:id>/pass-drafting/', route: '/jobs/:id/pass-drafting/' },
    'redo_drafting': { args: ['id'], rule: '/jobs/<int:id>/redo-drafting/', route: '/jobs/:id/redo-drafting/' },
    'resend_draft': { args: ['draft_id', 'job_id'], rule: '/jobs/<int:job_id>/draft/<int:draft_id>/resend/', route: '/jobs/:job_id/draft/:draft_id/resend/' },
    'draft_comment_new': { args: ['draft_id', 'job_id'], rule: '/jobs/<int:job_id>/drafts/<int:draft_id>/comments/', route: '/jobs/:job_id/drafts/:draft_id/comments/' },
    'draft_comment_new_only_with_job': { args: ['job_id'], rule: '/jobs/<int:job_id>/drafts/comments/', route: '/jobs/:job_id/drafts/comments/' },
    'components_list': { args: [], rule: '/components/', route: '/components/' },
    'component_new': { args: [], rule: '/components/new/', route: '/components/new/' },
    'component_copy': { args: ['identifier'], rule: '/components/copy/<int:identifier>', route: '/components/copy/:identifier' },
    'component_edit_jsx': { args: ['identifier'], rule: '/components/<int:identifier>/jsx/', route: '/components/:identifier/jsx/' },
    'component_edit_info': { args: ['identifier'], rule: '/components/<int:identifier>/info/', route: '/components/:identifier/info/' },
    'component_edit_embed': { args: ['identifier'], rule: '/components/<int:identifier>/embed/', route: '/components/:identifier/embed/' },
    'component_edit': { args: ['identifier'], rule: '/components/<int:identifier>/', route: '/components/:identifier/' },
    'email_counters_list': { args: [], rule: '/email_counters/', route: '/email_counters/' },
    'email_counter_reset': { args: [], rule: '/email_counter_reset/', route: '/email_counter_reset/' },
    'accept_invitation': { args: ['token', 'identifier'], rule: '/company-invite/<int:identifier>/<string:token>/', route: '/company-invite/:identifier/:token/' },
    'company_new': { args: [], rule: '/companies/new/', route: '/companies/new/' },
    'companies_list': { args: [], rule: '/companies/', route: '/companies/' },
    'company_edit': { args: ['identifier'], rule: '/companies/<int:identifier>/', route: '/companies/:identifier/' },
    'company_billing': { args: ['identifier'], rule: '/companies/<int:identifier>/billing/', route: '/companies/:identifier/billing/' },
    'company_shipment_settings': { args: ['identifier'], rule: '/companies/<int:identifier>/shipment/settings/', route: '/companies/:identifier/shipment/settings/' },
    'company_taxation': { args: ['identifier'], rule: '/companies/<int:identifier>/taxation/', route: '/companies/:identifier/taxation/' },
    'company_payment_options_phone': { args: ['identifier'], rule: '/companies/<int:identifier>/payment_options/phone/', route: '/companies/:identifier/payment_options/phone/' },
    'company_payment_options_bank': { args: ['identifier'], rule: '/companies/<int:identifier>/payment_options/bank/', route: '/companies/:identifier/payment_options/bank/' },
    'company_payment_options_paypal': { args: ['identifier'], rule: '/companies/<int:identifier>/payment_options/paypal/', route: '/companies/:identifier/payment_options/paypal/' },
    'company_payment_options_utrsut': { args: ['identifier'], rule: '/companies/<int:identifier>/payment_options/utrust/', route: '/companies/:identifier/payment_options/utrust/' },
    'company_payment_options_stripe': { args: ['identifier'], rule: '/companies/<int:identifier>/payment_options/stripe/', route: '/companies/:identifier/payment_options/stripe/' },
    'company_payment_options_square': { args: ['identifier'], rule: '/companies/<int:identifier>/payment_options/square/', route: '/companies/:identifier/payment_options/square/' },
    'company_payment_options': { args: ['identifier'], rule: '/companies/<int:identifier>/payment_options/', route: '/companies/:identifier/payment_options/' },
    'company_domains': { args: ['identifier'], rule: '/companies/<int:identifier>/domains/', route: '/companies/:identifier/domains/' },
    'company_invitations': { args: ['identifier'], rule: '/companies/<int:identifier>/invitations/', route: '/companies/:identifier/invitations/' },
    'company_members': { args: ['identifier'], rule: '/companies/<int:identifier>/members/', route: '/companies/:identifier/members/' },
    'company_ai': { args: ['identifier'], rule: '/companies/<int:identifier>/ai/', route: '/companies/:identifier/ai/' },
    'company_info': { args: ['identifier'], rule: '/companies/<int:identifier>/info/', route: '/companies/:identifier/info/' },
    'company_accept_stripe_edit': { args: ['identifier'], rule: '/companies/<int:identifier>/payment-options/accept-stripe', route: '/companies/:identifier/payment-options/accept-stripe' },
    'company_payment_option_square': { args: [], rule: '/company/payment_option/square/', route: '/company/payment_option/square/' },
    'company_delete': { args: ['id'], rule: '/companies/delete/<int:id>/', route: '/companies/delete/:id/' },
    'exchange_rates': { args: [], rule: '/exchange_rates/', route: '/exchange_rates/' },
    'shipments_list': { args: [], rule: '/shipments/', route: '/shipments/' },
    'shipment_new': { args: [], rule: '/shipments/new/', route: '/shipments/new/' },
    'shipment_edit': { args: ['id'], rule: '/shipments/<int:id>/', route: '/shipments/:id/' },
    'pass_shipping': { args: ['id'], rule: '/jobs/<int:id>/pass-shipping/', route: '/jobs/:id/pass-shipping/' },
    'redo_shipping': { args: ['id'], rule: '/jobs/<int:id>/redo-shipping/', route: '/jobs/:id/redo-shipping/' },
    'set_job_shipment_to_production_shipment': { args: ['id'], rule: '/jobs/<int:id>/set-job-shipment-to-production-shipment/', route: '/jobs/:id/set-job-shipment-to-production-shipment/' },
    'notify_job_dispatched': { args: ['job_id'], rule: '/notify-job-shipment/<int:job_id>/dispatched/', route: '/notify-job-shipment/:job_id/dispatched/' },
    'notify_assignment_shipment_dispatched': { args: ['job_id'], rule: '/notify-assignment-shipment/<int:job_id>/dispatched/', route: '/notify-assignment-shipment/:job_id/dispatched/' },
    'shipment_delete': { args: ['id'], rule: '/shipments/<int:id>/delete/', route: '/shipments/:id/delete/' },
    'public_host_domain_test': { args: ['host'], rule: '/test', route: '/test' },
    'public_host_domain_root': { args: ['host'], rule: '/', route: '/' },
    'public_host_public_login': { args: ['host'], rule: '/login/public/', route: '/login/public/' },
    'domain_uri_public_login': { args: ['id'], rule: '/domains/<int:id>/login/public/', route: '/domains/:id/login/public/' },
    'public_host_public_logout': { args: ['host'], rule: '/logout/public/', route: '/logout/public/' },
    'domain_uri_public_logout': { args: ['id'], rule: '/domains/<int:id>/logout/public/', route: '/domains/:id/logout/public/' },
    'public_host_public_domain_invite': { args: ['host', 'invite_id', 'invite_token'], rule: '/domain_invite/<int:invite_id>/<string:invite_token>/', route: '/domain_invite/:invite_id/:invite_token/' },
    'domain_uri_public_domain_invite': { args: ['invite_id', 'id', 'invite_token'], rule: '/domains/<int:id>/domain_invite/<int:invite_id>/<string:invite_token>/', route: '/domains/:id/domain_invite/:invite_id/:invite_token/' },
    'public_host_public_reset_password': { args: ['host'], rule: '/password/reset/request/public/', route: '/password/reset/request/public/' },
    'domain_uri_public_reset_password': { args: ['id'], rule: '/domains/<int:id>/password/reset/request/public/', route: '/domains/:id/password/reset/request/public/' },
    'public_host_public_password_change': { args: ['reset_id', 'host', 'token'], rule: '/password/reset/change/public/<int:reset_id>-<string:token>/', route: '/password/reset/change/public/:reset_id-:token/' },
    'domain_uri_public_password_change': { args: ['reset_id', 'id', 'token'], rule: '/domains/<int:id>/password/reset/change/public/<int:reset_id>-<string:token>/', route: '/domains/:id/password/reset/change/public/:reset_id-:token/' },
    'public_host_client_job_info': { args: ['host', 'job_id'], rule: '/jobs/<int:job_id>/info', route: '/jobs/:job_id/info' },
    'domain_uri_client_job_info': { args: ['id', 'job_id'], rule: '/domains/<int:id>/jobs/<int:job_id>/info', route: '/domains/:id/jobs/:job_id/info' },
    'public_host_client_job_profile': { args: ['host', 'job_id'], rule: '/jobs/<int:job_id>/', route: '/jobs/:job_id/' },
    'domain_uri_client_job_profile': { args: ['id', 'job_id'], rule: '/domains/<int:id>/jobs/<int:job_id>/', route: '/domains/:id/jobs/:job_id/' },
    'public_host_client_job_drafting': { args: ['host', 'job_id'], rule: '/jobs/<int:job_id>/drafting/public/', route: '/jobs/:job_id/drafting/public/' },
    'domain_uri_client_job_drafting': { args: ['id', 'job_id'], rule: '/domains/<int:id>/jobs/<int:job_id>/drafting/public/', route: '/domains/:id/jobs/:job_id/drafting/public/' },
    'public_host_client_job_file_download': { args: ['host', 'job_id'], rule: '/jobs/<int:job_id>/file_download/public/', route: '/jobs/:job_id/file_download/public/' },
    'domain_uri_client_job_file_download': { args: ['id', 'job_id'], rule: '/domains/<int:id>/jobs/<int:job_id>/file_download/public/', route: '/domains/:id/jobs/:job_id/file_download/public/' },
    'public_host_job_quote_requested_public': { args: ['host'], rule: '/job/quote-requested/public/', route: '/job/quote-requested/public/' },
    'domain_uri_job_quote_requested_public': { args: ['id'], rule: '/domains/<int:id>/job/quote-requested/public/', route: '/domains/:id/job/quote-requested/public/' },
    'public_host_draft_comment_new_public': { args: ['host', 'draft_id', 'job_id'], rule: '/jobs/<int:job_id>/drafts/<int:draft_id>/public/comments/', route: '/jobs/:job_id/drafts/:draft_id/public/comments/' },
    'domain_uri_draft_comment_new_public': { args: ['draft_id', 'id', 'job_id'], rule: '/domains/<int:id>/jobs/<int:job_id>/drafts/<int:draft_id>/public/comments/', route: '/domains/:id/jobs/:job_id/drafts/:draft_id/public/comments/' },
    'public_host_draft_comment_new_only_with_job_public': { args: ['host', 'job_id'], rule: '/jobs/<int:job_id>/drafts/public/comments/', route: '/jobs/:job_id/drafts/public/comments/' },
    'domain_uri_draft_comment_new_only_with_job_public': { args: ['id', 'job_id'], rule: '/domains/<int:id>/jobs/<int:job_id>/drafts/public/comments/', route: '/domains/:id/jobs/:job_id/drafts/public/comments/' },
    'public_host_client_invoice': { args: ['host', 'invoice_id'], rule: '/invoice/<int:invoice_id>/', route: '/invoice/:invoice_id/' },
    'domain_uri_client_invoice': { args: ['id', 'invoice_id'], rule: '/domains/<int:id>/invoice/<int:invoice_id>/', route: '/domains/:id/invoice/:invoice_id/' },
    'public_host_user_profile_public': { args: ['host', 'user_id'], rule: '/user/<int:user_id>/', route: '/user/:user_id/' },
    'domain_uri_user_profile_public': { args: ['user_id', 'id'], rule: '/domains/<int:id>/user/<int:user_id>/', route: '/domains/:id/user/:user_id/' },
    'public_host_company_profile_public': { args: ['company_id', 'host'], rule: '/company/<int:company_id>/', route: '/company/:company_id/' },
    'domain_uri_company_profile_public': { args: ['company_id', 'id'], rule: '/domains/<int:id>/company/<int:company_id>/', route: '/domains/:id/company/:company_id/' },
    'public_host_domain_index': { args: ['host'], rule: '/index/', route: '/index/' },
    'domain_uri_domain_index': { args: ['id'], rule: '/domains/<int:id>/index/', route: '/domains/:id/index/' },
    'public_host_domain_products_public': { args: ['host'], rule: '/products/', route: '/products/' },
    'domain_uri_domain_products_public': { args: ['id'], rule: '/domains/<int:id>/products/', route: '/domains/:id/products/' },
    'public_host_domain_product_public': { args: ['product_id', 'host'], rule: '/products/<int:product_id>/', route: '/products/:product_id/' },
    'domain_uri_domain_product_public': { args: ['product_id', 'id'], rule: '/domains/<int:id>/products/<int:product_id>/', route: '/domains/:id/products/:product_id/' },
    'public_host_invoice_paid_public': { args: ['host', 'invoice_id'], rule: '/invoice/<int:invoice_id>/paid/public/', route: '/invoice/:invoice_id/paid/public/' },
    'domain_uri_invoice_paid_public': { args: ['id', 'invoice_id'], rule: '/domains/<int:id>/invoice/<int:invoice_id>/paid/public/', route: '/domains/:id/invoice/:invoice_id/paid/public/' },
    'public_host_custom_page': { args: ['host', 'path'], rule: '/<path:path>/', route: '/:path/' },
    'domain_uri_custom_page': { args: ['path', 'id'], rule: '/domains/<int:id>/<path:path>/', route: '/domains/:id/:path/' },
    'public_host_public_sitemap': { args: ['host'], rule: '/sitemap', route: '/sitemap' },
    'domain_uri_public_sitemap': { args: ['id'], rule: '/domains/<int:id>/sitemap', route: '/domains/:id/sitemap' },
    'public_host_public_sitemap_slash': { args: ['host'], rule: '/sitemap/', route: '/sitemap/' },
    'domain_uri_public_sitemap_slash': { args: ['id'], rule: '/domains/<int:id>/sitemap/', route: '/domains/:id/sitemap/' },
    'public_host_public_sitemap_xml': { args: ['host'], rule: '/sitemap.xml', route: '/sitemap.xml' },
    'domain_uri_public_sitemap_xml': { args: ['id'], rule: '/domains/<int:id>/sitemap.xml', route: '/domains/:id/sitemap.xml' },
    'public_host_public_robots': { args: ['host'], rule: '/robots.txt', route: '/robots.txt' },
    'domain_uri_public_robots': { args: ['id'], rule: '/domains/<int:id>/robots.txt', route: '/domains/:id/robots.txt' },
    'public_host_public_robots_slash': { args: ['host'], rule: '/robots.txt/', route: '/robots.txt/' },
    'domain_uri_public_robots_slash': { args: ['id'], rule: '/domains/<int:id>/robots.txt/', route: '/domains/:id/robots.txt/' },
    'public_host_stripe_apple_pay_domain_association': { args: ['host'], rule: '/.well-known/apple-developer-merchantid-domain-association', route: '/.well-known/apple-developer-merchantid-domain-association' },
    'domain_uri_stripe_apple_pay_domain_association': { args: ['id'], rule: '/domains/<int:id>/.well-known/apple-developer-merchantid-domain-association', route: '/domains/:id/.well-known/apple-developer-merchantid-domain-association' },
    'humans': { args: [], rule: '/humans.txt/', route: '/humans.txt/' },
    'inbox': { args: [], rule: '/inbox/', route: '/inbox/' },
    'domain_new': { args: [], rule: '/domains/new/', route: '/domains/new/' },
    'domain_social': { args: ['identifier'], rule: '/domains/<int:identifier>/social/', route: '/domains/:identifier/social/' },
    'domain_ai': { args: ['identifier'], rule: '/domains/<int:identifier>/ai/', route: '/domains/:identifier/ai/' },
    'domain_seo': { args: ['identifier'], rule: '/domains/<int:identifier>/seo/', route: '/domains/:identifier/seo/' },
    'domain_widgets': { args: ['identifier'], rule: '/domains/<int:identifier>/widgets/', route: '/domains/:identifier/widgets/' },
    'domain_dns_settings': { args: ['identifier'], rule: '/domains/<int:identifier>/dns_settings/', route: '/domains/:identifier/dns_settings/' },
    'domain_api': { args: ['identifier'], rule: '/domains/<int:identifier>/api/', route: '/domains/:identifier/api/' },
    'domain_discounts': { args: ['identifier'], rule: '/domains/<int:identifier>/discounts/', route: '/domains/:identifier/discounts/' },
    'domain_themes': { args: ['identifier'], rule: '/domains/<int:identifier>/themes/', route: '/domains/:identifier/themes/' },
    'domain_tags': { args: ['identifier'], rule: '/domains/<int:identifier>/tags/', route: '/domains/:identifier/tags/' },
    'domain_tracking': { args: ['identifier'], rule: '/domains/<int:identifier>/tracking/', route: '/domains/:identifier/tracking/' },
    'domain_invitations': { args: ['identifier'], rule: '/domains/<int:identifier>/invitations/', route: '/domains/:identifier/invitations/' },
    'domain_users': { args: ['identifier'], rule: '/domains/<int:identifier>/users/', route: '/domains/:identifier/users/' },
    'domain_info': { args: ['identifier'], rule: '/domains/<int:identifier>/info/', route: '/domains/:identifier/info/' },
    'domain_carts': { args: ['identifier'], rule: '/domains/<int:identifier>/carts/', route: '/domains/:identifier/carts/' },
    'domain_analytics': { args: ['identifier'], rule: '/domains/<int:identifier>/analytics/', route: '/domains/:identifier/analytics/' },
    'domain_edit': { args: ['identifier'], rule: '/domains/<int:identifier>/', route: '/domains/:identifier/' },
    'domains_list': { args: [], rule: '/domains/', route: '/domains/' },
    'domain_delete': { args: ['id'], rule: '/domains/delete/<int:id>/', route: '/domains/delete/:id/' },
    'domain_new_theme': { args: ['domain_id'], rule: '/domains/<int:domain_id>/theme/new/', route: '/domains/:domain_id/theme/new/' },
    'domain_edit_theme': { args: ['theme_id', 'domain_id'], rule: '/domains/<int:domain_id>/theme/<int:theme_id>/', route: '/domains/:domain_id/theme/:theme_id/' },
    'domain_get_theme': { args: ['theme_id', 'domain_id'], rule: '/domains/<int:domain_id>/get-theme/<int:theme_id>/', route: '/domains/:domain_id/get-theme/:theme_id/' },
    'domain_secret_key_change': { args: ['domain_id'], rule: '/domains/<int:domain_id>/secret/change/', route: '/domains/:domain_id/secret/change/' },
    'domain_secret_key_revoke': { args: ['domain_id'], rule: '/domains/<int:domain_id>/secret/revoke/', route: '/domains/:domain_id/secret/revoke/' },
    'dashboard': { args: [], rule: '/dashboard/', route: '/dashboard/' },
    'public_host_merchi_shop_qr': { args: ['host'], rule: '/qr/', route: '/qr/' },
    'domain_uri_merchi_shop_qr': { args: ['id'], rule: '/domains/<int:id>/qr/', route: '/domains/:id/qr/' },
    'public_host_merchi_shop_qr_cart': { args: ['host'], rule: '/qr/cart/', route: '/qr/cart/' },
    'domain_uri_merchi_shop_qr_cart': { args: ['id'], rule: '/domains/<int:id>/qr/cart/', route: '/domains/:id/qr/cart/' },
    'public_host_merchi_shop_qr_cart_complete': { args: ['host'], rule: '/qr/cart/complete/', route: '/qr/cart/complete/' },
    'domain_uri_merchi_shop_qr_cart_complete': { args: ['id'], rule: '/domains/<int:id>/qr/cart/complete/', route: '/domains/:id/qr/cart/complete/' },
    'public_host_merchi_shop_qr_cart_clear': { args: ['host'], rule: '/qr/cart/clear/', route: '/qr/cart/clear/' },
    'domain_uri_merchi_shop_qr_cart_clear': { args: ['id'], rule: '/domains/<int:id>/qr/cart/clear/', route: '/domains/:id/qr/cart/clear/' },
    'public_host_merchi_shop_qr_cart_shipment': { args: ['host'], rule: '/qr/cart/shipment/', route: '/qr/cart/shipment/' },
    'domain_uri_merchi_shop_qr_cart_shipment': { args: ['id'], rule: '/domains/<int:id>/qr/cart/shipment/', route: '/domains/:id/qr/cart/shipment/' },
    'public_host_merchi_shop_qr_scanner': { args: ['host'], rule: '/qr/scanner/', route: '/qr/scanner/' },
    'domain_uri_merchi_shop_qr_scanner': { args: ['id'], rule: '/domains/<int:id>/qr/scanner/', route: '/domains/:id/qr/scanner/' },
    'public_host_merchi_shop_qr_cart_checkout': { args: ['host'], rule: '/qr/cart/checkout/', route: '/qr/cart/checkout/' },
    'domain_uri_merchi_shop_qr_cart_checkout': { args: ['id'], rule: '/domains/<int:id>/qr/cart/checkout/', route: '/domains/:id/qr/cart/checkout/' },
    'public_host_merchi_shop_qr_cart_item': { args: ['host', 'cart_item_id'], rule: '/qr/cart/item/<int:cart_item_id>/', route: '/qr/cart/item/:cart_item_id/' },
    'domain_uri_merchi_shop_qr_cart_item': { args: ['cart_item_id', 'id'], rule: '/domains/<int:id>/qr/cart/item/<int:cart_item_id>/', route: '/domains/:id/qr/cart/item/:cart_item_id/' },
    'public_host_merchi_shop_qr_product': { args: ['product_id', 'host'], rule: '/qr/products/<int:product_id>/', route: '/qr/products/:product_id/' },
    'domain_uri_merchi_shop_qr_product': { args: ['product_id', 'id'], rule: '/domains/<int:id>/qr/products/<int:product_id>/', route: '/domains/:id/qr/products/:product_id/' },
    'login_post': { args: [], rule: '/super/login/post/', route: '/super/login/post/' },
    'logout': { args: [], rule: '/logout/', route: '/logout/' },
    'back_to_admin': { args: [], rule: '/logout/return/', route: '/logout/return/' },
    'robots': { args: [], rule: '/robots.txt/', route: '/robots.txt/' },
    'production_comment_new': { args: ['id', 'assignment'], rule: '/jobs/<int:id>/comments/production/<int:assignment>/', route: '/jobs/:id/comments/production/:assignment/' },
    'assignment_refuse': { args: ['assignment_id', 'job_id'], rule: '/jobs/<int:job_id>/assignment/<int:assignment_id>/refuse/', route: '/jobs/:job_id/assignment/:assignment_id/refuse/' },
    'pass_production': { args: ['id'], rule: '/jobs/<int:id>/production/pass/', route: '/jobs/:id/production/pass/' },
    'pass_production_shipping': { args: ['id'], rule: '/jobs/<int:id>/production/pass-shipping/', route: '/jobs/:id/production/pass-shipping/' },
    'production_cancel': { args: ['job_id'], rule: '/jobs/<int:job_id>/assignment/cancel/', route: '/jobs/:job_id/assignment/cancel/' },
    'job_production_finish': { args: ['id'], rule: '/jobs/<int:id>/production/finish/', route: '/jobs/:id/production/finish/' },
    'job_production_commence': { args: ['id'], rule: '/jobs/<int:id>/production/commence/', route: '/jobs/:id/production/commence/' },
    'approve_supplier': { args: ['id'], rule: '/jobs/<int:id>/production/approve/', route: '/jobs/:id/production/approve/' },
    'sessions_list': { args: [], rule: '/sessions/', route: '/sessions/' },
    'session_delete': { args: ['token'], rule: '/sessions/delete/<string:token>/', route: '/sessions/delete/:token/' },
    'product_new': { args: [], rule: '/products/new/', route: '/products/new/' },
    'product_copy': { args: ['identifier'], rule: '/products/<int:identifier>/copy/', route: '/products/:identifier/copy/' },
    'products_list': { args: [], rule: '/products/', route: '/products/' },
    'product_edit': { args: ['identifier'], rule: '/products/<int:identifier>/', route: '/products/:identifier/' },
    'product_seo': { args: ['identifier'], rule: '/products/<int:identifier>/seo/', route: '/products/:identifier/seo/' },
    'product_jobs': { args: ['identifier'], rule: '/products/<int:identifier>/jobs/', route: '/products/:identifier/jobs/' },
    'product_chained_products': { args: ['identifier'], rule: '/products/<int:identifier>/chained/', route: '/products/:identifier/chained/' },
    'product_component': { args: ['identifier'], rule: '/products/<int:identifier>/component/', route: '/products/:identifier/component/' },
    'product_files': { args: ['identifier'], rule: '/products/<int:identifier>/files/', route: '/products/:identifier/files/' },
    'product_edit_info': { args: ['identifier'], rule: '/products/<int:identifier>/info/', route: '/products/:identifier/info/' },
    'product_edit_variations': { args: ['identifier'], rule: '/products/<int:identifier>/variations/', route: '/products/:identifier/variations/' },
    'product_edit_inventory': { args: ['identifier'], rule: '/products/<int:identifier>/inventory/', route: '/products/:identifier/inventory/' },
    'product_edit_payments': { args: ['identifier'], rule: '/products/<int:identifier>/payments/', route: '/products/:identifier/payments/' },
    'product_edit_shipping': { args: ['identifier'], rule: '/products/<int:identifier>/shipping/', route: '/products/:identifier/shipping/' },
    'product_edit_drafting': { args: ['identifier'], rule: '/products/<int:identifier>/drafting/', route: '/products/:identifier/drafting/' },
    'product_edit_production': { args: ['identifier'], rule: '/products/<int:identifier>/production/', route: '/products/:identifier/production/' },
    'product_edit_discounts': { args: ['identifier'], rule: '/products/<int:identifier>/discounts/', route: '/products/:identifier/discounts/' },
    'product_ai': { args: ['identifier'], rule: '/products/<int:identifier>/ai/', route: '/products/:identifier/ai/' },
    'product_delete': { args: ['id'], rule: '/product/delete/<int:id>/', route: '/product/delete/:id/' },
    'subscription_plans': { args: [], rule: '/subscription_plans/', route: '/subscription_plans/' },
    'invoice_new': { args: [], rule: '/invoices/new/', route: '/invoices/new/' },
    'invoice_edit': { args: ['identifier'], rule: '/invoices/<int:identifier>/', route: '/invoices/:identifier/' },
    'complete_paypal_transaction': { args: ['identifier'], rule: '/invoices/<int:identifier>/paypal-complete/', route: '/invoices/:identifier/paypal-complete/' },
    'complete_stripe_transaction': { args: ['identifier'], rule: '/invoices/<int:identifier>/stripe-complete/', route: '/invoices/:identifier/stripe-complete/' },
    'pending_utrust_transaction': { args: ['identifier'], rule: '/invoices/<int:identifier>/utrust-pending/', route: '/invoices/:identifier/utrust-pending/' },
    'invoices_list': { args: [], rule: '/invoices/', route: '/invoices/' },
    'notify': { args: ['invoice', 'job'], rule: '/notify/<int:invoice>/<int:job>', route: '/notify/:invoice/:job' },
    'file_new': { args: [], rule: '/files/new/', route: '/files/new/' },
    'files_post': { args: [], rule: '/files/new/', route: '/files/new/' },
    'files_list': { args: [], rule: '/files/', route: '/files/' },
    'file_delete': { args: ['id'], rule: '/files/delete/<int:id>/', route: '/files/delete/:id/' },
    'file_delete_route': { args: [], rule: '/files/delete-route/', route: '/files/delete-route/' },
    'automatic_payment_relationships': { args: [], rule: '/automatic_payment_relationships/', route: '/automatic_payment_relationships/' },
    null: null };
